import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'
/**
 * 路由过渡效果处理
 */
export const TankLoadable = (dir) => {
  const component = () => import(`@/pages${dir}`)
  return loadable(component, {
    fallback: <div style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spin tip='加载中...' size='large' /></div>
  })
}
