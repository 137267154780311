import React, { PureComponent } from 'react'
import BaseLayout from './basicLayout/Main'
import V2Layout from './V2Layout/Main'
import UserLayout from './userLayout/Main'
import { HashRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import { message } from 'antd'
import { routerList } from '@/routes/index'
import './style.styl'

// 设置最大显示数
message.config({ maxCount: 1 })

class Layout extends PureComponent {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
  }

  render () {
    return (
      <Router>
        <div className='wrapper'>
          <Switch>
            <Redirect exact from='/' to='/home' />
            <Redirect exact from='/V1.0' to='/V1.0/content/home' />
            {routerList.map(item => {
              if (item.routes && item.routes.length) {
                if (item.key === 'V1.0') {
                  return (<Route key={item.path} path='/V1.0' render={() => <BaseLayout route={item} />} />)
                }
                if (item.key === 'V2.0') {
                  return (<Route key={item.path} path='/V2.0' render={() => <V2Layout route={item} />} />)
                }
              } else {
                return (<Route key={item.path} path={`${item.path}`} component={item.component} />)
              }
            })}
            <Redirect to='/V2.0' />
          </Switch>
        </div>
      </Router>
    )
  }
}

export default Layout
