import React, { PureComponent } from 'react'
import SlideMenu from '../slide/Main'
import HeaderNav from '../header/Main'
import Bread from '../breadcrumb/Main'
import { withRouter } from 'react-router-dom'
import { routeGenerator } from '@/routes/index'
import { Layout } from 'antd'
import { getToken } from '@/utils/auth'
import store from '@/stores/index'
import './style.styl'

/* *
  主页面布局
*/
class V2Layout extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    this.loginState()
  }

  // 判断路由钩子
  loginState () {
    // 是否登录
    if (getToken()) {
      // 是否拉取用户信息
      if (store.userStore.role) {
      } else {
        // 拉取用户信息
      }
    } else {
      this.props.history.push({ pathname: '/login' })
    }
  }

  // 匹配展开项
  matchOpenKeys (pathname, route) {
    let n = 0
    return route.routes.filter(item => {
      if (item.routes && item.routes.length) {
        item.routes.map(k => {
          if (pathname.indexOf(k.path) > -1) n++
        })
        return n > 0
      } else {
        return item.path === pathname
      }
    })
  }

  render () {
    const { route } = this.props
    const { Footer, Content } = Layout
    return (
      <div className='fx-main'>
        <Layout className='fx-container'>
          <SlideMenu edition={2} openKeys={this.matchOpenKeys(this.props.location.pathname, route)} route={route} />
          <Layout className='fx-container-r'>
            <HeaderNav />
            <Bread />
            <Content className='fx-content'>
              <div className='fx-section'>
                {routeGenerator(route)}
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    )
  }
}

export default withRouter(V2Layout)
