let AdminTOken = 'token'

export const getToken = () => {
  return JSON.parse(localStorage.getItem(AdminTOken))
}

export const setToken = (token) =>  {
  localStorage.setItem(AdminTOken, JSON.stringify(token))
}

export const removeToken = () => {
  localStorage.removeItem(AdminTOken)
}