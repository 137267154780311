import React, { PureComponent } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { inject, observer } from 'mobx-react';
import { matchPermission } from '@/routes/index'
import './style.styl'
import classnames from 'classnames'

@inject('userStore')
@observer
class SlideMenu extends PureComponent {
  constructor(props) {
    const { openKeys } = props
    super(props)
    this.state = {
      openKeys: openKeys.length ? [openKeys[0].path] : [],
      routerMenu: []
    }
  }
  componentDidMount() { }
  // 监听菜单打开收起
  onOpenChange(openKeys) {
    this.setState({
      openKeys: openKeys
    })
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { openKeys } = nextProps
    this.setState({
      openKeys: openKeys.length ? [openKeys[0].path] : []
    })
  }
  handleClick(edition) {
    if (edition === 1) {
      // this.props.history.push({ pathname: '/V2.0' })
      window.open(`/#/V2.0/content`, '_blank')
    }
    if (edition === 2) {
      // this.props.history.push({ pathname: '/V1.0' })
      window.open(`/#/V1.0/content`, '_blank')
    }
    if (edition === 3) {
      window.open(`/robot`, '_blank')
    }
  }
  render() {
    const { route, edition } = this.props
    const { Sider } = Layout
    const { SubMenu } = Menu
    return (
      <React.Fragment >
        {/* <div  className={classnames('fx-container-2',{
   'no-width':this.props.userStore.foldSide
 })}>
          <div className="header-logo ">
            <img src={require('@/assets/img/logo.png')} alt="" />
          </div>
          </div> */}
        <Sider theme="light"  className={classnames('fx-container-l',{
   'no-width':this.props.userStore.foldSide
 })}>
          <div className="header-logo ">
            <img src={require('@/assets/img/logo.png')} alt="" />
            {/* <h3>飞选</h3> */}
          </div>
          <div className="slide-menu">
            <Menu
              onClick={this.handleClick}
              mode="inline"
              onOpenChange={this.onOpenChange.bind(this)}
              openKeys={this.state.openKeys}>
              {route.routes.map(item => {
                if (item.hidden || !matchPermission(item.auth)) return
                if (item.routes && item.routes.length) {
                  return <SubMenu
                    key={item.path}
                    title={item.name}>
                    {item.routes && item.routes.length && item.routes.map(subitem => {
                      if (subitem.hidden || !matchPermission(subitem.auth)) return
                      return <Menu.Item key={subitem.path}>
                        <NavLink to={subitem.path} activeClassName='active-route'> {subitem.name}
                          <span className='active-route-line'></span>
                        </NavLink>
                      </Menu.Item>
                    })}
                  </SubMenu>
                } else {
                  return <Menu.Item key={item.path}>
                    <NavLink to={item.path} activeClassName='active-route'> {item.name}
                      <span className='active-route-line'></span>
                    </NavLink>
                  </Menu.Item>
                }
              })}
              <Menu.Item className='route-robot'
                onClick={this.handleClick.bind(this, 3)}
                >微信机器人 </Menu.Item>
              <Menu.Item className='route-link'
                onClick={this.handleClick.bind(this, edition)}
                key={`V${edition === 1 ? 2 : 1}.0`}>V{edition === 1 ? 2 : 1}.0管理 </Menu.Item>

            </Menu>
          </div>
        </Sider>
      </React.Fragment>
    )
  }
}

export default withRouter(SlideMenu)