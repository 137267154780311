import axios from 'axios'
import store from '@/stores/index.js'
import { message } from 'antd'
import { createHashHistory } from "history"
import qs from 'qs'

let history = createHashHistory()

const service = axios.create({
  baseURL: '',
  timeout: 9000,
  transformRequest: [
    data => {
      let dataTypeOf = Object.prototype.toString.call(data)
      if (dataTypeOf === '[object FormData]' || dataTypeOf === '[object String]') {
        return data
      } else {
        if (data && data['postjson'] === 1) {
          return JSON.parse(JSON.stringify(JSON.stringify(data)))
        } else {
          return qs.stringify(data)
        }
      }
    }
  ]
})

// 请求拦截器
service.interceptors.request.use(config => {
  config.headers['sessionId'] = store.userStore.sessionId
  return config
}, err => {
  return Promise.reject(err)
})

// 响应拦截器
service.interceptors.response.use(response => {
  if (response.status === 200) {
    const result = response.data
    if (result.status === 200) {
      return result.data
    } else if (result.status === 400) {
      if (response.config.url === '/activity/invite/code/remove/activity') return
      message.error(result.data)
      return Promise.reject(result.data)
    } else if (result.status === 401) {
      message.info('登录失效, 重新登录')
      store.userStore.logout().then(() => {
        history.push('/login')
      })
      return Promise.reject(result.data)
    }
    return Promise.reject(result.data)
  } else {
    message.error('服务异常,请联系管理员')
  }
}, err => {
  if (err.message.includes('timeout')) {   // 判断请求异常信息中是否含有超时timeout字符串
    // console.log("错误回调", error);
    return Promise.reject('请求超时');          // reject这个错误信息
  }
  return Promise.reject(err)
})

export default service