import React from 'react'
import store from '@/stores/index'
import * as Auth from '@/utils/auth'
import { routerList } from './route'
import { Switch, Redirect, Route, useHistory, Link } from 'react-router-dom'
import { Result, Button } from 'antd'
// 匹配权限
function matchPermission(auth) {
  const role = store.userStore.role; let isAuth = false
  if (auth) {
    if (auth.includes(role)) {
      isAuth = true
    } else {
      isAuth = false
    }
  } else {
    isAuth = true
  }
  return isAuth
}
// 路由生成器
const routeGenerator = (route) => {
  return (
    <Switch>
      <Redirect exact from={route.path} to={route.routes[0].path} />
      {route.routes.map((route, i) => {
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => {
              if (!matchPermission(route.auth)) {
                return (
                  <Result
                    status='403'
                    title='403'
                    subTitle='对不起, 你没有访问权限'
                    extra={<Button type='link'>请联系管理员</Button>}
                  />
                )
              } else {
                return <route.component {...props} route={route} />
              }
            }}
          />
        )
      })}
      <Route
        path='*'
        render={(props) => {
          return (
            <Result
              status='404'
              title='404'
              subTitle='访问的页面不存在'
              extra={<Button type='primary'><Link to='/'>返回首页</Link></Button>}
            />
          )
        }}
      />
    </Switch>
  )
}

export {
  routerList,
  matchPermission,
  routeGenerator
}
