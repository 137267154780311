import Request from '@/utils/request'

export const login = (data) => {
  return Request({
    url: '/auth/login',
    method: 'post',
    data
  })
}

export const logout = (data) => {
  return Request({
    url: '/auth/logout',
    method: 'post',
    data
  })
}
