import { observable, action } from 'mobx'
import { login, logout } from '@/api/userInfo'
import encryptUtils from '@/utils/aes'
import * as Auth from '@/utils/auth'

class UserStore {
  @observable token = localStorage.getItem('token') || 'null'
  @observable sessionId = localStorage.getItem('sessionId') || 'null'
  @observable userId = localStorage.getItem('userId') || 'null'
  @observable realName = localStorage.getItem('realName') || 'null'
  @observable username = localStorage.getItem('username') || 'null'
  @observable groupId = localStorage.getItem('groupId') || 'null'
  @observable groupType = localStorage.getItem('groupType') || 'null'
  @observable role = localStorage.getItem('role') || 'null'
  @observable permissions = localStorage.getItem('permissions') || []
  @observable foldSide = false
  @observable foldTop = true

  // 侧边栏展开折叠
  @action changeFold(){
    this.foldSide=!this.foldSide
  }
  @action changeTop(){
    this.foldTop=!this.foldTop
  }
  // 登录
  @action login({ username, password, code }) {
    return new Promise((resolve, reject) => {
      // 调登录接口
      let data = {
        username,
        password: encryptUtils.aesEncrypt(password),
        checkCode: code
      }
      login(data).then(data => {
        const { principal, details } = data

        let userInfo = {
          token: principal.token,
          sessionId: details.sessionId,
          userId: principal.userId,
          username: principal.username,
          realName: principal.realName,
          groupId: principal.groupId,
          groupType: principal.groupType,
        }
        for (const key in userInfo) {
          if (userInfo.hasOwnProperty(key)) {
            const value = userInfo[key]
            localStorage.setItem(key, value)
            this.setter(key, value)
          }
        }
        Auth.setToken(principal.userId)
        resolve()
      })
    })
  }
  // 退出
  @action logout() {
    return new Promise((resolve, resjec) => {
      // 调退出接口
      logout().then(data => {
        Auth.removeToken()
        localStorage.clear()
        resolve()
      })
    })
  }
  // 获取用户信息
  @action userInfo() {

  }
  // 赋值
  @action setter(key, value) {
    this[key] = value
  }
  // 枚举赋值
  @action enum(user) {
    for (const key in user) {
      this.setter(key, user[key])
    }
  }
}

export default new UserStore()