import React, { PureComponent } from 'react'
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import { PoweroffOutlined } from '@ant-design/icons';
import { Layout, Button } from 'antd';
import './style.styl'
import classnames from 'classnames'
@inject('userStore')
@observer
class HeaderNav extends PureComponent {
  constructor(props) {
    super(props)
    this.username = this.props.userStore.username
  }
  // 注销
  logout() {
    this.props.userStore.logout().then(() => {
      this.props.history.push({ pathname: '/login' })
    })
  }
  render() {
    const { Header } = Layout
    return (
      <Header className={classnames('fx-header',{
        'no-height':this.props.userStore.foldTop
      })}>
        <div className="user-info">
          <Button type="link">{this.username}</Button>
          <PoweroffOutlined onClick={this.logout.bind(this)} />
        </div>
      </Header>
    );
  }
}

export default withRouter(HeaderNav)