import crypto from './crypto-browserify'

export default {
  aesEncrypt (data, secretKey = 'feixuan') {
    var cipher = crypto.createCipher('aes-128-ecb', secretKey)
    return cipher.update(data, 'utf8', 'hex') + cipher.final('hex')
  },
  aesDecrypt (data, secretKey = 'feixuan') {
    var cipher = crypto.createDecipher('aes-128-ecb', secretKey)
    return cipher.update(data, 'hex', 'utf8') + cipher.final('utf8')
  }
}
