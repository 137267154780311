import { observable, action } from 'mobx'

class Content {
  @observable imageTypes = {
    0: {name: '通用图片', type: 0, show: false},
    1: {name: '轮播图', type: 1, show: true},
    2: {name: '焦点图', type: 2, show: false},
    3: {name: '图标', type: 3, show: true},
    4: {name: '专栏图', type: 4, show: true},
    5: {name: 'banner图', type: 5, show: true},
    6: {name: '启动页大图', type: 6, show: true}
  }
}

export default new Content()