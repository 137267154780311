import React from 'react'
import { Provider } from 'mobx-react'
import stores from '@/stores/index'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import 'moment/locale/zh-cn'
import './assets/fonts/iconfont';
import './styles/app'
import Layout from './layouts/Main'
function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <Provider {...stores}>
        <Layout />
      </Provider>
    </ConfigProvider>
  )
}

export default App
