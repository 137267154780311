import React, { PureComponent } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import { Breadcrumb } from 'antd'
import { matchRoutes } from 'react-router-config'
import { routerList } from '@/routes/index'
import { DoubleLeftOutlined, DoubleRightOutlined,DownSquareOutlined,UpSquareOutlined } from '@ant-design/icons'
import './style.styl'

@inject('userStore')
@observer
class Bread extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    const { location } = this.props
    const matchedRoutes = matchRoutes(routerList, location.pathname)
    if (location.pathname === "/welcome") {
      return (
        <Breadcrumb className='fx-breadcrumb'>
          <Link style={{ color: '#e93c50' }} to={location.pathname}>系统首页</Link>
        </Breadcrumb>
      )
    } else {
      return (
        <Breadcrumb className='fx-breadcrumb'>
          {this.props.userStore.foldSide ? (
            <DoubleRightOutlined className='fold-icon' onClick={
              () => { this.props.userStore.changeFold() }} />
          ) : (
            <DoubleLeftOutlined className='fold-icon' onClick={
              () => { this.props.userStore.changeFold() }} />
          )}
        {this.props.userStore.foldTop ? (
            <DownSquareOutlined className='fold-icon' onClick={
              () => { this.props.userStore.changeTop() }} />
          ) : (
            <UpSquareOutlined className='fold-icon' onClick={
              () => { this.props.userStore.changeTop() }} />
          )}
          {matchedRoutes.map((matchRoute, i) => {
            const { path, name } = matchRoute.route
            if (!name) return
            const isActive = path === location.pathname
            return i > 1 || isActive ? (
              <Breadcrumb.Item key={i}>
                <Link style={{ color: '#e93c50' }} to={path}>{name}</Link>
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item key={i}>
                {name}
                {/* <Link style={{ color: '#e93c50' }} to={path}>{name}</Link> */}
              </Breadcrumb.Item>
            )
          })}
        </Breadcrumb>
      )
    }
  }
}

export default withRouter(Bread)